import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './_modules/material.module';
import { ToastrModule } from 'ngx-toastr';
import { BlogPageComponent } from './blog-page/blog-page.component';

// Redux
import { StoreModule } from '@ngrx/store';
import { counterReducer } from './_state_management/counter.reducer';
import { FormsModule } from '@angular/forms';
import { AnalyticsEBookComponent } from './analytics/_component/analytics-e-book/analytics-e-book.component';
import { LengthEqualZeroPipe } from './_pipes/length-equal-zero.pipe';

@NgModule({
  declarations: [AppComponent, BlogPageComponent, AnalyticsEBookComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({
      count: counterReducer,
    }),
  ],
  providers: [ToastrModule],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {

  appAdmin: any = JSON.parse(localStorage.getItem('appAdmin'));

  message: string;
  alertClass: string;
  slug:any;
  detailData:any;
  objLoading:any;

  constructor(
    private pageService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer:DomSanitizer,
    public location: Location
  ) { 
    this.slug = this.route.snapshot.paramMap.get("slug");
  }

  ngOnInit() {
    this.getObjListing();
  }

  getObjListing() {
    this.objLoading = true;
    this.pageService.getBlogBySlug(this.slug)
      .subscribe(
        res => {
          this.detailData = res['data'];
          this.objLoading = false;
        },
        error => {
          this.handleError(error);
          this.objLoading = false;
        }
      );
    return false;
  }

  handleError(error: any) {
    if (error.status == 401) {
      this.alertClass = "alert-danger";
      this.message = error.error['message'];
    }
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }

}

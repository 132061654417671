import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlingService } from '../_services/error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl: any = environment.apiUrl;
  // Header
  appUser: any = JSON.parse(localStorage.getItem('appUser')) || {token: ''};
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-auth-token': this.appUser.token
  });
  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient,
  ) { }

  sendEnquiry(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/enquiry', reqData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, error.error))
      );
  }

  signupNewsletter(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/newsletter/registration', reqData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, error.error))
      );
  }

  // Sign up Request
  create(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/users', reqData)
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  // Login Request
  login(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/auth', reqData)
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  // Forgot Password Request
  forgotPassword(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/users/forgot-paasword', reqData)
      .pipe(
        map(response => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  // Reset Url Request
  resetUrl(id: any) {
    return this.httpClient.get(this.apiUrl + '/users/forgot-paasword' + id)
      .pipe(
        map(response => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  // Reset Password
  resetPassword(reqData: any) {
    return this.httpClient.post(this.apiUrl + '/users/forgot-paasword', reqData)
      .pipe(
        map(response => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }


  // Update Password
  updatePassword(reqData: any) {
      const options = { headers: this.headers };
      return this.httpClient.post(this.apiUrl + '/users/forgot-paasword', reqData, options)
        .pipe(
          map((response: Response) => response),
          catchError(error => this.errorHandlingService.handleError(error, ''))
        );
  }

  // verifyEmail Request
  verifyEmail(id: any) {
    return this.httpClient.get(this.apiUrl + '/users/forgot-paasword' + id)
      .pipe(
        map(response => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  // get blog details using slug
  getBlogBySlug(slug: any) {
    return this.httpClient.get(this.apiUrl + '/blog/byslug/' + slug)
      .pipe(
        map(response => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

}



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guard/auth.guard';
import { AdminGuard } from './_guard/admin.guard';
import { BlogPageComponent } from './blog-page/blog-page.component';

const routes: Routes = [
  
  {path: 'blog-page/:slug', component: BlogPageComponent },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  }, {
  //   path: 'user',
  //   loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  //   canActivate: [AuthGuard]
  // },
  // {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  }, {
    path: '*',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-e-book',
  templateUrl: './analytics-e-book.component.html',
  styleUrls: ['./analytics-e-book.component.scss']
})
export class AnalyticsEBookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
